import revive_payload_client_KoayFSvBDF from "C:/Users/zreat/Documents/Projects/ludmilaphoto/node_modules/.pnpm/nuxt@3.14.1592_sass@1.82.0_typescript@5.7.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_4KSGRS68NK from "C:/Users/zreat/Documents/Projects/ludmilaphoto/node_modules/.pnpm/nuxt@3.14.1592_sass@1.82.0_typescript@5.7.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_AuGFmG0VpU from "C:/Users/zreat/Documents/Projects/ludmilaphoto/node_modules/.pnpm/nuxt@3.14.1592_sass@1.82.0_typescript@5.7.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_vdarZi0uZN from "C:/Users/zreat/Documents/Projects/ludmilaphoto/node_modules/.pnpm/nuxt-site-config@2.2.21/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_tCrqQZSVWE from "C:/Users/zreat/Documents/Projects/ludmilaphoto/node_modules/.pnpm/nuxt@3.14.1592_sass@1.82.0_typescript@5.7.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Kv9nMck97z from "C:/Users/zreat/Documents/Projects/ludmilaphoto/node_modules/.pnpm/nuxt@3.14.1592_sass@1.82.0_typescript@5.7.2/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_H90iz29OxT from "C:/Users/zreat/Documents/Projects/ludmilaphoto/node_modules/.pnpm/nuxt@3.14.1592_sass@1.82.0_typescript@5.7.2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_27YW96mSGn from "C:/Users/zreat/Documents/Projects/ludmilaphoto/node_modules/.pnpm/nuxt@3.14.1592_sass@1.82.0_typescript@5.7.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "C:/Users/zreat/Documents/Projects/ludmilaphoto/.nuxt/components.plugin.mjs";
import prefetch_client_HumdkJFoXL from "C:/Users/zreat/Documents/Projects/ludmilaphoto/node_modules/.pnpm/nuxt@3.14.1592_sass@1.82.0_typescript@5.7.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vuetify_icons_5gOm3goTW1 from "C:/Users/zreat/Documents/Projects/ludmilaphoto/node_modules/.pnpm/vuetify-nuxt-module@0.7.3_typescript@5.7.2/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import vuetify_no_client_hints_GzGJK9w2SS from "C:/Users/zreat/Documents/Projects/ludmilaphoto/node_modules/.pnpm/vuetify-nuxt-module@0.7.3_typescript@5.7.2/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.mjs";
import vuetify_z29CnJ8EeB from "C:/Users/zreat/Documents/Projects/ludmilaphoto/node_modules/.pnpm/vuetify-nuxt-module@0.7.3_typescript@5.7.2/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify.mjs";
export default [
  revive_payload_client_KoayFSvBDF,
  unhead_4KSGRS68NK,
  router_AuGFmG0VpU,
  _0_siteConfig_vdarZi0uZN,
  payload_client_tCrqQZSVWE,
  navigation_repaint_client_Kv9nMck97z,
  check_outdated_build_client_H90iz29OxT,
  chunk_reload_client_27YW96mSGn,
  components_plugin_KR1HBZs4kY,
  prefetch_client_HumdkJFoXL,
  vuetify_icons_5gOm3goTW1,
  vuetify_no_client_hints_GzGJK9w2SS,
  vuetify_z29CnJ8EeB
]