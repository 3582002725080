import { default as aboutZgpY0CylnwMeta } from "C:/Users/zreat/Documents/Projects/ludmilaphoto/pages/about.vue?macro=true";
import { default as indexcXCJgolI9kMeta } from "C:/Users/zreat/Documents/Projects/ludmilaphoto/pages/index.vue?macro=true";
import { default as servicesQRVxghgzX0Meta } from "C:/Users/zreat/Documents/Projects/ludmilaphoto/pages/services.vue?macro=true";
import { default as vanoceFgeNtz0xzGMeta } from "C:/Users/zreat/Documents/Projects/ludmilaphoto/pages/vanoce.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("C:/Users/zreat/Documents/Projects/ludmilaphoto/pages/about.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("C:/Users/zreat/Documents/Projects/ludmilaphoto/pages/index.vue")
  },
  {
    name: "services",
    path: "/services",
    component: () => import("C:/Users/zreat/Documents/Projects/ludmilaphoto/pages/services.vue")
  },
  {
    name: "vanoce",
    path: "/vanoce",
    component: () => import("C:/Users/zreat/Documents/Projects/ludmilaphoto/pages/vanoce.vue")
  }
]