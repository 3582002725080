<template>
  <Head>
    <Meta name="description" content="Rodinná fotografka z Prahy" />
  </Head>
  <v-app>
    <v-main>
      <NuxtPage />
    </v-main>
    <siteblocks-footer />
  </v-app>
</template>
